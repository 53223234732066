import { useEffect, useState } from "react";
import { axiosApiGet, axiosApiDelete } from "../../network/api/RequestsApi";
import OrdersList from "./OrdersList";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DayInWeek } from '../../Logic/dayInWeek'

const OrdersListLogic = () => {

  const initialOrderList = [{}, {}, {}, {}]
  const [ordersList, setOrderList] = useState(initialOrderList);
  const [isLoading, setIsLoading] = useState(true);
  const [showNoOrdersPopup, setShowNoOrdersPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const userCode = useSelector((state) => state?.detail?.user_code || 1);

  const textDocument = useSelector((state) => state.textDocument || {});
  const envDefaults = useSelector((state) => state.envDefaults);
  const navigate = useNavigate();

  //Get list game 
  const getMyOrderList = async () => {
    setIsLoading(true);


    const res = await axiosApiGet(`/${envDefaults.gameOrderRoute}`);

    setIsLoading(false);

    if (res?.payload?.length) {

      res.payload.forEach(payload => {
        const player = payload.players.find(player => player.user_code.toString() === userCode.toString());
        // Move the player to position 0 if found
        if (player) {
          payload.players.unshift(payload.players.splice(payload.players.indexOf(player), 1)[0]);
        }
      });

      //Add the code here
      DayInWeek(res.payload, textDocument, setOrderList)
    } else {
      setOrderList(initialOrderList)
      setErrorMessage(textDocument.noOrdersPopus)
      setShowNoOrdersPopup(true);
    }
  };

  //Cancel participation, delete the game
  const onCancelParticipation = async (removeGame) => {
    const res = await axiosApiDelete(`/${envDefaults.gameOrderRoute}`, { ...removeGame[0], remove_all: false })

    //After cancel, geting the games, without the deleted game
    if (res?.status === 'failed' || res?.status === "fail") {
      setErrorMessage(textDocument.FailedDeleteFriend)
    }
    else if (res?.payload) {
      setErrorMessage(textDocument.GameRemovedSuccessfully)
      setTimeout(() => {
        getMyOrderList();
      }, 2000);
    }
    setShowNoOrdersPopup(true);

  };

  //geting the games
  useEffect(() => {
    getMyOrderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Editing a game, goes to the editing page : gameOrderConfirmation
  const editOrder = async (order, date, selectedOrderTime) => {
    navigate(`/gameOrder/gameTime/gameOrderConfirmation?selectedDate=${date}&selectedTime=${selectedOrderTime}`, { state: { order: order, ordersList: true } });
  }
  const goMain = async () => {
    navigate('/main');
  }
  return (
    <div>
      <OrdersList
        isLoading={isLoading}
        ordersList={ordersList}
        onCancelParticipation={onCancelParticipation}
        editOrder={editOrder}
        showNoOrdersPopup={showNoOrdersPopup}
        setShowNoOrdersPopup={setShowNoOrdersPopup}
        goMain={goMain}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default OrdersListLogic;
