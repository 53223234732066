import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { axiosApiGet } from "../../../network/api/RequestsApi";
import playerImg from '../../../images/pic-member-entrance.png';
import styled from 'styled-components';

// const ModernContainer = styled.div`
//   padding: 20px;
//   border-radius: 12px;
//   background: white;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
// `;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

// const SearchWrapper = styled.div`
//   width: 300px;
// `;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #2196f3;
    box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  }
`;

const DropdownList = styled.ul`
  position: fixed;
  top: ${props => props.inputPosition.bottom}px;
  left: ${props => props.inputPosition.left}px;
  width: ${props => props.inputPosition.width}px;
  max-height: 300px;
  overflow-y: auto;
  margin: 4px 0;
  padding: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  list-style: none;
`;

const DropdownItem = styled.li`
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #f5f5f5;
  }
`;

const FriendImage = styled.img`
  width: 100px;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
  margin-right: 2%;
`;

const ErrorMessage = styled.div`
  color: #f44336;
  margin-top: 8px;
  font-size: 14px;
`;

const PopAddFriends = ({ setSelectedFriendsCode }) => {
    const [friendsList, setFriendsList] = useState();
    const [friendImg, setFriendImg] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [error, setError] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [inputPosition, setInputPosition] = useState({ top: 0, left: 0, width: 0, bottom: 0 });

    const envDefaults = useSelector((state) => state.envDefaults);
    const textDocument = useSelector((state) => state.textDocument || {});

    useEffect(() => {
        const fetchFriends = async () => {
            const res = await axiosApiGet(`/${envDefaults.customersRoute}`);
            if (res?.payload) {
                setFriendsList(res.payload);
            }
        };
        fetchFriends();
    }, [envDefaults.customersRoute]);

    useEffect(() => {
        const updatePosition = () => {
            const input = document.querySelector('.search-input');
            if (input) {
                const rect = input.getBoundingClientRect();
                setInputPosition({
                    top: rect.top,
                    left: rect.left,
                    width: rect.width,
                    bottom: rect.bottom
                });
            }
        };

        updatePosition();
        window.addEventListener('resize', updatePosition);
        return () => window.removeEventListener('resize', updatePosition);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.search-wrapper')) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);  // הוספת נגיעה למובייל
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);  // ניקוי
        };
    }, []);

    const selectFriendImg = async (code) => {
        const res = await axiosApiGet(`/${envDefaults.customerPictureRoute}`, { customer_code: code });
        if (res?.status === "failed" || res?.status === "fail") {
            setSelectedFriendsCode();

            setError(textDocument[res?.error_message] ?? textDocument?.ServerProblem);
            return;
        }
        setSelectedFriendsCode(code)

        const picture = res?.payload?.picture
            ? res.payload.picture.replace(/^data:image\/\w+;base64,/, '')
            : playerImg.replace(/^data:image\/\w+;base64,/, '');
        setFriendImg(picture);
    };

    const filteredFriendsList = friendsList?.filter(friend => {
        const searchTerm = searchInput.trim().toLowerCase();

        if (friend.code === searchTerm) return true;

        const nameWords = friend.name?.toLowerCase().split(' ') || [];
        const latinNameWords = friend.latin_name?.toLowerCase().split(' ') || [];

        const fullName = nameWords.join(' ');
        const fullLatinName = latinNameWords.join(' ');

        const nameMatch = fullName.includes(searchTerm);
        const latinMatch = fullLatinName.includes(searchTerm);
        const codeMatch = friend.code?.toLowerCase().includes(searchTerm);

        return nameMatch || latinMatch || codeMatch;
    });

    const handleSelectFriend = (friend) => {
        setSearchInput(friend.name);
        // setSelectedFriendsCode(friend.code);
        selectFriendImg(friend.code);
        setShowDropdown(false);
    };

    return (
        
        <>
            <SearchContainer>
                <div className="search-wrapper" style={{ width: '100%' }}>
                    <SearchInput
                        className="search-input"
                        type="text"
                        placeholder={textDocument?.chooseFriend}
                        value={searchInput}
                        onChange={(e) => {
                            setError();
                            setSelectedFriendsCode()
                            setSearchInput(e.target.value);
                            setShowDropdown(true);
                        }}
                        onFocus={() => setShowDropdown(true)}
                    />
                    {showDropdown && filteredFriendsList?.length > 0 && (
                        <DropdownList inputPosition={inputPosition}>
                            {filteredFriendsList.map((friend, index) => (
                                <DropdownItem
                                    key={index}
                                    onClick={() => handleSelectFriend(friend)}
                                    onTouchStart={(e) => e.preventDefault()}  // מניעת ��עיות נגיעה במובייל
                                >
                                    <span>
                                        {textDocument.language !== "Hebrew"
                                            ? friend.name
                                            : friend.latin_name ?? friend.name}
                                    </span>
                                    <span style={{ color: '#666', fontSize: '0.9em' }}>
                                        {friend.code}
                                    </span>
                                </DropdownItem>
                            ))}
                        </DropdownList>
                    )}
                </div>
                {friendImg && (
                    <FriendImage
                        src={`data:image/jpeg;base64,${friendImg}`}
                        alt="Friend"
                    />
                )}
            </SearchContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
    );
};

export default PopAddFriends;

